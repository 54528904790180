import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "animation"
    }}>{`Animation`}</h1>
    <Vimeo id={436096727} mdxType="Vimeo" />
    <p>{`Welcome to the animation section. This is where the real fun begins. Demos that
look cool, impress your friends, and sound good at the dinner table.`}</p>
    <p>{`At my dinner table at least ...`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/puking-rainbows.png",
        "alt": null
      }}></img></p>
    <p>{`You already know how React and D3 work together, so these examples are going to
go faster. You know that we're using React for rendering SVG and D3 for
calculating props. You know how to make your dataviz interactive and how to
handle oodles of data.`}</p>
    <p>{`Now you're going to learn how to make it dance. Smooth transitions between
states, complex animations, interacting with users in real-time. 60 frames per
second, baby!`}</p>
    <p>{`Animation looks like this in a nutshell:`}</p>
    <ul>
      <li parentName="ul">{`render something`}</li>
      <li parentName="ul">{`change it 60 times per second`}</li>
    </ul>
    <p>{`When that happens humans perceive smooth motion. You can go as low as 24 frames
per second, like old TVs used to, but that often looks jaggeddy on modern
monitors.`}</p>
    <p>{`You're about to learn two different approaches to building these animations.
Using game loops and using transitions.`}</p>
    <ol>
      <li parentName="ol">{`Game loops give you more control`}</li>
      <li parentName="ol">{`Transitions are easier to implement`}</li>
    </ol>
    <p>{`We're starting with an example or two in CodeSandbox, then building some bigger
stuff. No more big huge projects like the `}<a parentName="p" {...{
        "href": "/tech-salaries/1"
      }}>{`tech salary visualization`}</a>{`, though. Takes too long to set up and doesn't focus on animation.`}</p>
    <h1 {...{
      "id": "using-a-game-loop-for-rich-animation"
    }}>{`Using a game loop for rich animation`}</h1>
    <Vimeo id={436096512} mdxType="Vimeo" />
    <p>{`Game loops are fun! They're my favorite. They even sound fun: "game loop".
Doesn't it sound fun to go build a game loop? Maybe it's because I've always
used game loops when building something fun to play with.`}</p>
    <p>{`At its core, a game loop is an infinite loop where each iteration renders the
next frame of your animation.`}</p>
    <p>{`The concept comes from the video game industry. At some point they realized
that building game engines is much easier when you think about each frame as
its own static representation of your game state.`}</p>
    <p>{`You take every object in the game and render it. Then you throw it all away,
make small adjustments, and render again.`}</p>
    <p>{`This turns out to be both faster to run and easier to implement than diffing
scenes and figuring out what moves and what doesn't. Of course as you get more
objects on the screen it becomes silly to re-render the immovable background
every time.`}</p>
    <p>{`But you don't have to worry about that. That's React's job.`}</p>
    <p>{`React can figure out a diff between hierarchical representations of your scene
and re-render the appropriate objects.`}</p>
    <p>{`That's a hard engineering problem, but you can think of it this way:`}</p>
    <ol>
      <li parentName="ol">{`Render a DOM from state`}</li>
      <li parentName="ol">{`Change some values in state`}</li>
      <li parentName="ol">{`Trigger a re-render`}</li>
    </ol>
    <p>{`Make those state changes fast enough and you get 60 frames per second. The hard
part then becomes thinking about your movement as snapshots in time.`}</p>
    <p>{`When something speeds up, what does that mean for changes in its position?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      